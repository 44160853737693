import React, { useRef } from 'react';
import useOnClickOutside from 'use-onclickoutside';

export const TaskClickCatcher = ({
  refForWrapper,
  onClick,
  children
}) => {
  const ref = useRef(null);
  useOnClickOutside(refForWrapper, e => {
    if (ref && ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const offsetTopUserClick = e.pageY;
      const offsetTopElement = rect.top;
      const height = rect.height;

      if (
        offsetTopUserClick >= offsetTopElement &&
        offsetTopUserClick <= offsetTopElement + height
      ) {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }
    } else {
      console.warn('No ref.current :|');
    }
  });
  return <div ref={ref}>{children}</div>;
};
