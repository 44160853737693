import { Grid } from '@material-ui/core';
import React from 'react';

export const TasksWrapper = ({ children, ...props }) => (
  <Grid container justify={'center'} {...props}>
    <Grid item xs={10} sm={10} md={6}>
      <br />
      <br />
      <br />
      {children}
    </Grid>
  </Grid>
);
