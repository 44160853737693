export const withReducerLogger = (
  opts = {
    enabled: true
  }
) => reducer => (state, action) => {
  const stateNext = reducer(state, action);
  if (opts.enabled) {
    console.log('\n');
    console.log(`Action`);
    console.log(action);
    console.log(`State \t`);
    console.log(state);
  }

  return stateNext;
};
