import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';
/*
TODO
- import from another feature
*/
import { getInstancesCompletedCount } from 'features/Home/helpers';
import { useLocalStorage } from 'hooks/use-localstorage';
import {
  flow,
  map,
  reverse,
  sortBy,
  toPairs
} from 'lodash/fp';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  deserializeInstances,
  getDayHeader
} from 'shared/helpers';

export const Stats = () => {
  const [db] = useLocalStorage({
    key: 'ALWAYS_NOW_V4',
    initialValue: {},
    deserialize: deserializeInstances
  });

  const array = flow(
    toPairs,
    map(item => ({
      time: item[0],
      tasks: item[1]
    })),
    sortBy(['time']),
    reverse
  )(db);

  return (
    <div>
      <AppBar position={'static'} elevation={0}>
        <Toolbar variant={'dense'}>
          <Typography
            style={{
              flexGrow: '1'
            }}
            variant={'subtitle1'}
          >
            Stats
          </Typography>
          <Button component={Link} to={'/'}>
            Main page
          </Button>
        </Toolbar>
      </AppBar>
      <Grid container>
        {map(({ time, tasks }) => (
          <Grid key={time} item xs={12} sm={6} md={4}>
            <br />
            <br />
            <Typography
              align={'center'}
              variant={'h3'}
              gutterBottom
              color={
                getInstancesCompletedCount(tasks) > 0
                  ? 'primary'
                  : 'textSecondary'
              }
            >
              {getInstancesCompletedCount(tasks)}
            </Typography>
            <Typography
              align={'center'}
              variant={'caption'}
              gutterBottom
            >
              {getDayHeader(
                moment(new Date(window.parseInt(time)))
              )}
            </Typography>
            <br />
            <br />
          </Grid>
        ))(array)}
      </Grid>
    </div>
  );
};
