import { Button } from '@material-ui/core';
import React from 'react';
import { useLocalStorage } from 'hooks/use-localstorage';
import { STORAGE_KEY_FOR_SETTINGS } from 'shared/constants';
import { Redirect } from 'react-router-dom';

export const LandingPageCTA = () => {
  const [settings, setSettings] = useLocalStorage({
    key: STORAGE_KEY_FOR_SETTINGS,
    initialValue: {}
  });
  return (
    <>
      {settings.SHOW_ABOUT === false ? (
        <Redirect to={'/'} />
      ) : null}
      <Button
        color={'primary'}
        variant={'contained'}
        size={'large'}
        onClick={() => {
          setSettings({
            ...settings,
            SHOW_ABOUT: false
          });
        }}
        style={{
          minWidth: '160px',
          color: 'white',
          background:
            'linear-gradient(to right, #f5af19, #f12711)'
        }}
      >
        Try Now For FREE
      </Button>
    </>
  );
};
