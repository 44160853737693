import {
  moveSelectionEndDown,
  moveSelectionEndUp,
  moveSelectionStartDown,
  moveSelectionStartUp
} from 'features/Home/reducer.selections';
import {
  copyInstanceAndDoGood,
  findIndexByKey,
  findLastGroupIndex,
  getDraggableEndAuto,
  getInstanceDataBlock,
  getInstanceDepth,
  getInstancesFocus,
  getInstanceText,
  isOperationIsRevertable,
  isRangeIsGroup
} from 'features/Home/helpers';
import { List } from 'immutable';
import { withReducerLogger } from 'lib/with-reducer-logger';
import {
  compose,
  flow,
  isNumber,
  map,
  reverse,
  sortBy,
  times
} from 'lodash/fp';
import { createInstance, generateKey, getKey } from 'utils';
import { DAY_DURATION_MS } from 'shared/constants';

const convertSelectionToDraggable = range => {
  /* INFO
    - mapping is obligatory to avoid mutation
  */
  if (!range) return range;
  return range.concat().sort((a, b) => (a > b ? 1 : -1));
};

const setInstanceDepth = instance => depth => {
  const PATH = ['document', 'data', 'depth'];
  return instance.setIn(PATH, depth);
};

const removeFocusFromInstances = instances => {
  const indexWithFocus = instances.findIndex(
    getInstancesFocus
  );

  if (isNumber(indexWithFocus) && indexWithFocus >= 0) {
    return instances.setIn(
      [indexWithFocus, 'selection', 'isFocused'],
      false
    );
  } else {
    return instances;
  }
};

/*
  TODO
  - remove focus from currently focused instance
*/
export const add = () => instances => {
  const key = generateKey();
  return instances.push(
    createInstance({
      key,
      text: '',
      isFocused: true,
      depth: 0
    })
  );
};

export const split = ({ key, offset }) => instances => {
  const index = findIndexByKey(key)(instances);

  const instance = instances.get(index);
  const text = getInstanceText(instance);
  const instanceMod = copyInstanceAndDoGood({
    instance,
    text: text.slice(0, offset),
    isFocused: false
  });
  const instanceAdded = createInstance({
    key: generateKey(),
    text: text.slice(offset, text.length),
    isFocused: true,
    offset: 0,
    depth: getInstanceDepth(instance)
  });

  return instances
    .set(index, instanceMod)
    .insert(index + 1, instanceAdded);
};

export const focusByKeyFromLeft = ({
  key
}) => instances => {
  const index = findIndexByKey(key)(instances);
  const instanceToFocus = copyInstanceAndDoGood({
    instance: instances.get(index),
    isFocused: true,
    offset: 0
  });

  // TODO replace previously focused instance
  return instances.set(index, instanceToFocus);
};

export const instancesFocusByKeyDown = ({
  key,
  offset
}) => instances => {
  const index = findIndexByKey(key)(instances);
  const indexNext = index + 1;

  if (indexNext >= instances.size) {
    return instances;
  }
  const instanceToUnfocus = copyInstanceAndDoGood({
    instance: instances.get(index),
    isFocused: false
  });

  const instanceToFocus = copyInstanceAndDoGood({
    instance: instances.get(indexNext),
    offset,
    isFocused: true
  });

  return instances
    .set(index, instanceToUnfocus)
    .set(indexNext, instanceToFocus);
};

export const instancesFocusByKeyUp = ({
  key,
  offset
}) => instances => {
  const index = findIndexByKey(key)(instances);
  const indexNext = index - 1;

  if (indexNext < 0) {
    return instances;
  }
  const instanceToUnfocus = copyInstanceAndDoGood({
    instance: instances.get(index),
    isFocused: false
  });
  const instanceToFocus = copyInstanceAndDoGood({
    instance: instances.get(indexNext),
    offset,
    isFocused: true
  });

  return instances
    .set(index, instanceToUnfocus)
    .set(indexNext, instanceToFocus);
};

export const removeByKey = ({ key }) => instances => {
  if (instances.size <= 1) return List([]);

  const index = findIndexByKey(key)(instances);
  const indexToFocus = index === 0 ? index + 1 : index - 1;
  const instanceToFocus = copyInstanceAndDoGood({
    instance: instances.get(indexToFocus),
    isFocused: true
  });

  return flow(
    relateByKey({ key, shift: true }),
    instances =>
      instances.set(indexToFocus, instanceToFocus),
    instances => instances.delete(index)
  )(instances);
};

export const removeByRange = ({ range }) => instances => {
  if (instances.size <= 1) return List([]);
  const RANGE_MIN = Math.min(range[0], range[1]);

  const keys = flow(
    sortBy(n => n),
    range => range[1] - range[0] + 1,
    number => times(n => n + RANGE_MIN)(number),

    map(index => getKey(instances.get(index))),
    reverse
  )(range);

  return keys.reduce((instances, key) => {
    return removeByKey({ key })(instances);
  }, instances);
};

export const update = ({ key, value }) => instances => {
  const index = findIndexByKey(key)(instances);
  return instances.set(index, value);
};

export const instancesJoin = ({ key }) => instances => {
  const index = findIndexByKey(key)(instances);
  const indexPrev = index - 1;
  const instance = instances.get(index);

  if (index === 0) return instances;

  const textFromPrev = getInstanceText(
    instances.get(indexPrev)
  );
  const textFromCurrent = getInstanceText(instance);
  const text = textFromPrev + textFromCurrent;

  const instanceNew = createInstance({
    key: generateKey(),
    text,
    isFocused: true,
    offset: textFromPrev.length,
    data: getInstanceDataBlock(instances.get(indexPrev)),
    depth: getInstanceDepth(instances.get(indexPrev))
  });

  return flow(
    relateByKey({ key, shift: true }),
    i => i.set(indexPrev, instanceNew),
    i => i.delete(index)
  )(instances);
};

export const relateByRange = ({
  range: [indexFirst, indexLast],
  shift
}) => instances => {
  const instance = instances.get(indexFirst);
  const depth = getInstanceDepth(instance);
  const depthUpperParentAll = [
    ...instances.slice(0, indexFirst)
  ].map(getInstanceDepth);
  const depthUpperParentMin = depthUpperParentAll.sort(
    (a, b) => a - b
  )[0];
  const depthUpperParentNext = getInstanceDepth(
    instances.get(indexFirst - 1)
  );

  const delta =
    shift && indexFirst === 0 && depth > 0
      ? -1
      : depthUpperParentAll.length === 0
      ? 0
      : shift
      ? depthUpperParentMin < depth
        ? -1
        : 0
      : depthUpperParentNext >= depth
      ? 1
      : 0;

  for (let i = indexFirst; i <= indexLast; i++) {
    let instance = instances.get(i);
    let depth = getInstanceDepth(instance) + delta;
    instances = instances.set(
      i,
      setInstanceDepth(instance)(depth)
    );
  }
  return instances;
};

export const relateByKey = ({
  key,
  shift
}) => instances => {
  const indexFirst = findIndexByKey(key)(instances);
  const indexLast = findLastGroupIndex(indexFirst)(
    instances
  );
  return relateByRange({
    range: [indexFirst, indexLast],
    shift
  })(instances);
};

export const replace = ({
  indexSource,
  indexSourceEnd,
  indexDestination,
  depth
}) => instances => {
  const depthSource = getInstanceDepth(
    instances.get(indexSource)
  );

  /* NOTE
    — Moving item up visually
    — Moving item down in terms of array indexes
  */

  if (indexDestination <= indexSource) {
    return [...new Array(indexSourceEnd - indexSource + 1)]
      .map((v, i) => i)
      .reduce((instances, index) => {
        const depthOld = getInstanceDepth(
          instances.get(indexSource + index)
        );
        const depthDelta = depthOld - depthSource;
        const depthNew = depth + depthDelta;
        const instanceNew = setInstanceDepth(
          instances.get(indexSource + index)
        )(depthNew);

        return instances
          .insert(indexDestination + index, instanceNew)
          .delete(indexSource + 1 + index);
      }, instances);
  }

  /* NOTE
    — Moving item down visually
    — Moving item up in terms of array indexes
  */
  if (indexDestination > indexSource) {
    return [...new Array(indexSourceEnd - indexSource + 1)]
      .map((v, i) => i)
      .reduce((instances, index) => {
        const depthOld = getInstanceDepth(
          instances.get(indexSource)
        );
        const depthDelta = depthOld - depthSource;
        const depthNew = depth + depthDelta;
        const instanceNew = setInstanceDepth(
          instances.get(indexSource)
        )(depthNew);

        return instances
          .insert(indexDestination, instanceNew)
          .delete(indexSource);
      }, instances);
  }
};

const ADD_EMPTY = (state, action) => {
  return {
    ...state,
    instances: add()(state.instances),
    rangeDraggable: [0, 0],
    rangeSelection: null
  };
};

const FOCUS_FROM_FOCUS = (state, action) => {
  const { key } = action.payload;
  const { instances } = state;
  const index = findIndexByKey(key)(instances);
  return {
    ...state,
    rangeDraggable: [index, index],
    rangeSelection: null
  };
};

const FOCUS_FROM_LEFT = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: focusByKeyFromLeft({
      key: action.payload.key
    })(state.instances),
    rangeSelection: null
  };
};

const FOCUS_UP = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: instancesFocusByKeyUp({
      key: action.payload.key,
      offset: action.payload.offset
    })(state.instances)
  };
};

const FOCUS_DOWN = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: instancesFocusByKeyDown({
      key: action.payload.key,
      offset: action.payload.offset
    })(state.instances)
  };
};

const SPLIT = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: split({
      key: action.payload.key,
      offset: action.payload.offset
    })(state.instances)
  };
};
const REMOVE = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: removeByKey({
      key: action.payload.key
    })(state.instances),
    rangeDraggable: [
      state.instances.size > 1 ? 0 : undefined,
      state.instances.size > 1 ? 0 : undefined
    ]
  };
};

const REMOVE_BY_RANGE = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: removeByRange({
      range: state.rangeSelection
    })(state.instances),
    rangeSelection: null,
    rangeDraggable: [
      state.instances.size > 1 ? 0 : undefined,
      state.instances.size > 1 ? 0 : undefined
    ]
  };
};
/*
  TODO
  - WHAT?
*/
const REPLACE = (state, action) => {
  const { indexSource, indexDestination } = action.payload;
  const rangeDraggableLength = Math.abs(
    state.rangeDraggable[1] - action.payload.indexSource + 1
  );
  const rangeNew =
    indexDestination > indexSource
      ? [
          indexDestination - rangeDraggableLength,
          indexDestination - 1
        ]
      : [
          indexDestination,
          indexDestination + rangeDraggableLength - 1
        ];
  const rangeSelection = !!state.rangeSelection
    ? rangeNew
    : state.rangeSelection;

  return {
    ...state,
    history: state.history.push(state.instances),
    instances: replace({
      indexSource: action.payload.indexSource,
      indexSourceEnd: state.rangeDraggable[1],
      indexDestination: action.payload.indexDestination,
      depth: action.payload.depth
    })(state.instances),
    rangeSelection,
    rangeDraggable: rangeNew
  };
};
const SAVE = (state, action) => {
  const { operations, key, value } = action.payload;
  const { history, instances } = state;
  console.log(operations.toJS());
  return {
    ...state,
    history: isOperationIsRevertable(operations)
      ? history.push(instances)
      : history,
    instances: update({
      key,
      value
    })(instances),
    rangeSelection: null
  };
};
const JOIN = (state, action) => {
  const { key } = action.payload;
  const { instances, history } = state;
  return {
    ...state,
    history: history.push(instances),
    instances: instancesJoin({
      key
    })(instances),
    rangeDraggable: [0, 0]
  };
};
const UNDO = (state, action) => {
  return {
    ...state,
    history: state.history.pop(),
    instances:
      state.history.size > 0
        ? state.history.last()
        : state.instances
  };
};
const TOGGLE_RELATION = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: relateByKey({
      key: action.payload.key,
      shift: action.payload.shift
    })(state.instances)
  };
};
const TOGGLE_RELATION_SELECTION = (state, action) => {
  return {
    ...state,
    history: state.history.push(state.instances),
    instances: relateByRange({
      range: state.rangeDraggable,
      shift: action.payload.shift
    })(state.instances)
  };
};
const CHANGE_SELECTION_DRAGGABLE = (state, action) => {
  const { key } = action.payload;
  const { instances } = state;

  const index = findIndexByKey(key)(instances);
  return {
    ...state,
    rangeDraggable: [
      index,
      getDraggableEndAuto({
        instances,
        rangeDraggableStart: index
      })
    ]
  };
};
const SELECTION_INIT_DOWN = (state, action) => {
  const indexStart = findIndexByKey(action.payload.key)(
    state.instances
  );
  const indexEnd = findLastGroupIndex(indexStart)(
    state.instances
  );
  const rangeSelection = [indexStart, indexEnd];

  return {
    ...state,
    instances: removeFocusFromInstances(state.instances),
    rangeSelection,
    rangeSelectionDirection: 'DOWN',
    rangeDraggable: convertSelectionToDraggable(
      rangeSelection
    )
  };
};

const SELECTION_INIT_UP = (state, action) => {
  const indexStart = findIndexByKey(action.payload.key)(
    state.instances
  );
  const indexEnd = findLastGroupIndex(indexStart)(
    state.instances
  );
  const rangeSelection = [indexStart, indexEnd];

  return {
    ...state,
    instances: removeFocusFromInstances(state.instances),
    rangeSelection,
    rangeSelectionDirection: 'UP',
    rangeDraggable: convertSelectionToDraggable(
      rangeSelection
    )
  };
};
const SELECTION_MOVE_UP = (state, action) => {
  const rangeSelectionDirection = isRangeIsGroup(state)
    ? 'UP'
    : state.rangeSelectionDirection;
  const rangeSelection =
    rangeSelectionDirection === 'UP'
      ? moveSelectionStartUp(state)
      : moveSelectionEndUp(state);

  isRangeIsGroup(state);

  return {
    ...state,
    rangeSelection,
    rangeSelectionDirection,
    rangeDraggable: convertSelectionToDraggable(
      rangeSelection
    )
  };
};

const SELECTION_MOVE_DOWN = (state, action) => {
  const rangeSelectionDirection = isRangeIsGroup(state)
    ? 'DOWN'
    : state.rangeSelectionDirection;

  const rangeSelection =
    rangeSelectionDirection === 'DOWN'
      ? moveSelectionEndDown(state)
      : moveSelectionStartDown(state);

  return {
    ...state,
    rangeSelection,
    rangeSelectionDirection,
    rangeDraggable: convertSelectionToDraggable(
      rangeSelection
    )
  };
};

const SELECTION_RESET = (state, action) => {
  return {
    ...state,
    instances: flow(
      state => ({
        index: state.rangeSelection[0],
        instances: state.instances
      }),
      ({ index, instances }) => ({
        key: getKey(instances.get(index)),
        instances
      }),
      ({ key, instances }) =>
        focusByKeyFromLeft({ key })(instances)
    )(state),
    rangeSelection: null
  };
};
const SET_INSTANCES = (state, action) => {
  return {
    ...state,
    instances: action.payload.instances
  };
};
const DATE_PREV = (state, action) => {
  return {
    ...state,
    date: new Date(state.date.getTime() - DAY_DURATION_MS),
    history: List()
  };
};
const DATE_NEXT = (state, action) => {
  return {
    ...state,
    date: new Date(state.date.getTime() + DAY_DURATION_MS),
    history: List()
  };
};

const MAP_OF_ACTIONS_TO_FUNCTIONS = {
  ADD_EMPTY: ADD_EMPTY,
  CHANGE_SELECTION_DRAGGABLE: CHANGE_SELECTION_DRAGGABLE,
  DATE_NEXT: DATE_NEXT,
  DATE_PREV: DATE_PREV,
  FOCUS_DOWN: FOCUS_DOWN,
  FOCUS_FROM_FOCUS: FOCUS_FROM_FOCUS,
  FOCUS_FROM_LEFT: FOCUS_FROM_LEFT,
  FOCUS_UP: FOCUS_UP,
  JOIN: JOIN,
  REMOVE_BY_RANGE: REMOVE_BY_RANGE,
  REMOVE: REMOVE,
  REPLACE: REPLACE,
  SAVE: SAVE,
  SELECTION_INIT_DOWN: SELECTION_INIT_DOWN,
  SELECTION_INIT_UP: SELECTION_INIT_UP,
  SELECTION_MOVE_DOWN: SELECTION_MOVE_DOWN,
  SELECTION_MOVE_UP: SELECTION_MOVE_UP,
  SELECTION_RESET: SELECTION_RESET,
  SET_INSTANCES: SET_INSTANCES,
  SPLIT: SPLIT,
  TOGGLE_RELATION_SELECTION: TOGGLE_RELATION_SELECTION,
  TOGGLE_RELATION: TOGGLE_RELATION,
  UNDO: UNDO
};

export const reducer = compose(
  withReducerLogger({ enabled: false })
)((state, action) => {
  console.log('> ACTION');
  console.log(action);
  if (MAP_OF_ACTIONS_TO_FUNCTIONS[action.type]) {
    return MAP_OF_ACTIONS_TO_FUNCTIONS[action.type](
      state,
      action
    );
  } else {
    throw new Error('DAN_ABRAMOV_SAYS_DOING_THAT');
  }
});
