import { Checkbox, Typography } from '@material-ui/core';
import React from 'react';
import {
  BOX_SIZE,
  BOX_SIZE_PADDING
} from 'shared/constants';

export const TaskNode = props => {
  const { editor, node } = props;
  const isDone = node.getIn(['data', 'isDone']) || false;
  const depth = editor.value.getIn([
    'document',
    'data',
    'depth'
  ]);

  const onChangeCheckbox = () => {
    editor.setNodeByKey(node.key, {
      data: {
        isDone: !isDone
      }
    });
  };

  return (
    <div
      {...props.attributes}
      style={{
        display: 'flex',
        paddingLeft: `${BOX_SIZE * depth}px`
      }}
    >
      <Checkbox
        contentEditable={false}
        checked={isDone}
        style={{
          margin: '0px',
          padding: `${BOX_SIZE_PADDING}px 0px`,
          alignItems: 'normal'
        }}
        tabIndex={-1}
        onChange={onChangeCheckbox}
        value={'isDone'}
        color={'primary'}
      />

      <Typography
        variant={'subtitle1'}
        color={isDone ? 'textSecondary' : 'initial'}
        style={{
          padding: `${BOX_SIZE_PADDING}px 5px`,
          margin: '0px',
          minHeight: `${BOX_SIZE - BOX_SIZE_PADDING}px`,
          textDecoration: isDone
            ? 'line-through'
            : undefined,
          flexGrow: '1'
        }}
      >
        {props.children}
      </Typography>
    </div>
  );
};
