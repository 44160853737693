import {
  Avatar,
  Divider,
  Grid,
  Hidden,
  Typography
} from '@material-ui/core';
import { green, grey, red } from '@material-ui/core/colors';
import { LandingPageCTA } from 'features/LanginPageCTA';
import React from 'react';

const SectionSeparator = () => (
  <>
    <br />
    <br />
    <br />
    <br />
  </>
);

const HighlightedText = ({ children }) => (
  <span style={{ color: 'orange' }}>{children}</span>
);

const Container = ({ children }) => (
  <div style={{ maxWidth: '80%', marginLeft: '10%' }}>
    {children}
  </div>
);

const Logo = () => {
  return (
    <>
      <Hidden xsDown>
        <Typography
          variant={'h1'}
          gutterBottom
          style={{
            fontWeight: 'bold'
          }}
          align={'center'}
        >
          AlwaysNow
        </Typography>
      </Hidden>

      <Hidden smUp>
        <Typography
          variant={'h2'}
          gutterBottom
          style={{
            fontWeight: 'bold'
          }}
          align={'center'}
        >
          AlwaysNow
        </Typography>
      </Hidden>
    </>
  );
};

export const LandingPage2 = () => (
  <div style={{ backgroundColor: 'black' }}>
    <br />
    <br />
    <br />
    <Container>
      <Typography
        variant={'h5'}
        align={'center'}
        gutterBottom
      >
        It's
      </Typography>

      <Logo />
      <Typography variant={'h5'} align={'center'}>
        — <HighlightedText>text editor</HighlightedText>{' '}
        boosted with{' '}
        <HighlightedText>nested tasks</HighlightedText> with
        a strong{' '}
        <HighlightedText>focus on NOW</HighlightedText>
      </Typography>
    </Container>
    <br />
    <img
      alt={'My avatar'}
      src={'./images/image2.png '}
      style={{
        width: '100%'
      }}
    />
    <br />
    <br />
    <br />
    <br />
    <div style={{ textAlign: 'center' }}>
      <LandingPageCTA />
    </div>
    <br />
    <br />
    <Divider />
    <br />
    <br />
    <Typography variant={'overline'} align={'center'}>
      ?
    </Typography>
    <br />
    <br />
    <Grid container alignItems={'center'}>
      <Grid item md={12}>
        <div style={{ padding: '0px 40px' }}>
          <Typography
            variant={'h3'}
            align={'center'}
            gutterBottom
          >
            An app that{' '}
            <span
              style={{
                textDecoration: 'line-through',
                color: grey[800]
              }}
            >
              overwhelms
            </span>{' '}
            improves you
          </Typography>
          <Typography
            variant={'h6'}
            align={'center'}
            color={'textSecondary'}
          >
            Less Planning. More doing.
          </Typography>
          <br />
        </div>
      </Grid>
      <br />
      <Container>
        <Grid item md={12}>
          <Grid container spacing={40}>
            {[
              'priorities',
              'due dates',
              'projects and tags'
            ].map(title => (
              <Grid
                key={Math.random()}
                item
                md={6}
                style={{}}
              >
                <div
                  style={{
                    mingHeight: '120px',
                    padding: '16px 40px',
                    borderLeft: `8px solid ${red[500]}`
                  }}
                >
                  <Typography variant={'h5'}>
                    NO {title}
                  </Typography>
                  <Typography>
                    Focus on NOW instead.
                  </Typography>
                </div>
              </Grid>
            ))}
            <Grid
              key={Math.random()}
              item
              md={6}
              style={{}}
            >
              <div
                style={{
                  mingHeight: '120px',
                  padding: '16px 40px',
                  borderLeft: `8px solid ${green[500]}`
                }}
              >
                <Typography variant={'h5'}>
                  Visual Everyday Perfomance
                </Typography>
                <Typography>How about that?</Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>

    <br />
    <br />

    <Grid container>
      <Grid item xs={12}>
        <div style={{ padding: '20px' }}>
          <Typography
            variant={'h3'}
            align={'center'}
            gutterBottom
          >
            This simple. So powerful
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            textAlign: 'center',
            padding: '0px 20px'
          }}
        >
          <img
            alt={'My avatar'}
            src={'./images/app.png '}
            style={{
              maxWidth: '100%',
              border: `1px solid ${grey[900]}`
            }}
          />
        </div>
      </Grid>
    </Grid>
    <br />
    <br />
    <br />
    <div style={{ textAlign: 'center' }}>
      <LandingPageCTA />
    </div>
    <br />
    <br />
    <br />
    <Container>
      <Grid container alignItems={'center'}>
        <Grid item md={12}>
          <Typography variant={'h3'}>
            Works for somebody
          </Typography>
          <br />
          <br />
        </Grid>
        <Grid item md={12}>
          <Grid container>
            <Grid item md={2}>
              <Avatar
                alt={'My avatar'}
                src={'./images/ava.jpg '}
                style={{
                  maxWidth: '100%',
                  width: '140px',
                  height: '140px'
                }}
              />
              <br />
            </Grid>
            <Grid item md={10}>
              <div
                style={{
                  borderLeft: '2px solid orange',
                  paddingLeft: '20px'
                }}
              >
                <Typography variant={'h6'}>
                  For years I was chasing different
                  productivity tools until I realised my
                  personal truth. The problem with all those
                  productivity tools was me.
                </Typography>

                <Typography variant={'h6'}>
                  Now I am organising the current moment and
                  focus on simple step by step execution.
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
    <SectionSeparator />
  </div>
);
