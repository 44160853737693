export const BOX_SIZE = 28;
export const BOX_SIZE_PADDING = 1;

export const DAY_DURATION_MS = 1000 * 60 * 60 * 24;

export const STORAGE_KEY_FOR_INSTANCES =
  'ALWAYS_NOW_V4_INSTANCES';

export const STORAGE_KEY_FOR_SETTINGS =
  'ALWAYS_NOW_V4_SETTINGS';
