import { useState, useEffect } from 'react';

const readFromLocalStorageSafe = key => {
  try {
    const parsed = JSON.parse(localStorage.getItem(key));
    return parsed;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const useLocalStorage = ({
  key,
  initialValue,
  deserialize = d => d
}) => {
  const initialData = () => {
    return deserialize(
      readFromLocalStorageSafe(key) || initialValue
    );
  };

  const [data, setData] = useState(initialData);

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (e) {
      console.log(e);
      console.log(data);
    }
  }, [data, key]);

  return [data, setData];
};
