import React from 'react';
import { grey } from '@material-ui/core/colors';

export const TasksSelected = ({ children }) => (
  <div
    style={{ backgroundColor: grey[900], boxShadow: '1px' }}
  >
    {children}
  </div>
);
