import hotkeys from 'hotkeys-js';
import { useEffect } from 'react';

export default function useHotKeys(keys, opts, callback) {
  useEffect(() => {
    hotkeys(keys, opts, callback);
    return function cleanUp() {
      hotkeys.unbind(keys);
    };
  });
}
