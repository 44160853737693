import { flow, map, join, flatten } from 'lodash/fp';

export const getEditorPureText = editor => {
  const text = flow(
    editor => editor.value,
    value => value.blocks,
    blocks => blocks.toJS(),
    /* https://github.com/GitbookIO/slate-no-empty/blob/master/src/index.js */
    map(block => block.nodes),
    flatten,
    map(block => block.text),
    join('')
  )(editor);
  return text;
};

export const isEditorHasNoText = editor => {
  const text = getEditorPureText(editor);
  return !text || text.length === 0;
};

export const getIndexLastCharOfLine = () => {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  const caretIndex = range.startOffset;
  const rect = range.getBoundingClientRect();
  const container = range.startContainer;
  const lastIndex = container.length;

  for (let i = caretIndex; i < lastIndex; i++) {
    const rangeTest = document.createRange();
    rangeTest.setStart(container, i);
    const rectTest = rangeTest.getBoundingClientRect();
    // if the y is different it means the test range is in a different line
    if (rectTest.y !== rect.y) return i - 1;
  }

  return lastIndex;
};

export const getIndexFirstCharOfLine = () => {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);
  const caretIndex = range.startOffset;
  const rect = range.getBoundingClientRect();
  const container = range.startContainer;
  const firstIndex = 0;

  for (let i = caretIndex; i > 0; i--) {
    const rangeTest = document.createRange();
    rangeTest.setStart(container, i);
    const rectTest = rangeTest.getBoundingClientRect();
    // if the y is different it means the test range is in a different line
    if (rectTest.y !== rect.y) return i + 1;
  }
  return firstIndex;
};

export const getCurrentOffset = editor => {
  return editor.value.getIn([
    'selection',
    'focus',
    'offset'
  ]);
};

export const getEditorSelection = editor => {
  return editor.value.fragment.text;
};

export const getEditorDoneStatus = editor => {
  return (
    editor.value.getIn([
      'document',
      'nodes',
      '0',
      'data',
      'isDone'
    ]) || false
  );
};
