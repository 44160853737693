import {
  findLastGroupIndex,
  getTravelIndexPrevious
} from 'features/Home/helpers';

export const moveSelectionEndUp = ({
  instances,
  rangeSelection
}) => {
  const rangeSelectionStart = rangeSelection[0];

  const rangeSelectionEnd = findLastGroupIndex(
    Math.max(rangeSelection[1] - 1, 0)
  )(instances);
  return [rangeSelectionStart, rangeSelectionEnd];
};

export const moveSelectionEndDown = ({
  instances,
  rangeSelection
}) => {
  const rangeSelectionStart = rangeSelection[0];

  const rangeSelectionEnd = findLastGroupIndex(
    Math.min(rangeSelection[1] + 1, instances.size - 1)
  )(instances);
  return [rangeSelectionStart, rangeSelectionEnd];
};

export const moveSelectionStartDown = ({
  instances,
  rangeSelection
}) => {
  const rangeSelectionStart = Math.min(
    rangeSelection[0] + 1,
    instances.size - 1
  );

  const rangeSelectionEnd = rangeSelection[1];

  return [rangeSelectionStart, rangeSelectionEnd];
};

export const moveSelectionStartUp = ({
  instances,
  rangeSelection
}) => {
  const rangeSelectionStart = getTravelIndexPrevious(
    rangeSelection[0]
  );
  const rangeSelectionEnd = findLastGroupIndex(
    rangeSelectionStart
  )(instances);

  return [
    rangeSelectionStart,
    Math.max(rangeSelectionEnd, rangeSelection[1])
  ];
};
