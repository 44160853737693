import { createMuiTheme } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';

export const theme = createMuiTheme({
  palette: {
    primary: green,
    type: 'dark'
  },
  typography: {
    useNextVariants: true
  },
  overrides: {
    MuiSvgIcon: {
      colorAction: {
        color: grey[500]
      }
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: 'black'
      }
    }
  }
});
