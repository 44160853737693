import ConditionalWrap from 'conditional-wrap';
import { TasksItemSource } from 'features/Tasks/components/TasksItemSource';
import { TasksPlaceholder } from 'features/Tasks/components/TasksPlaceholder';
import { TasksSelected } from 'features/Tasks/components/TasksSelected';
import { TasksItemTarget } from 'features/Tasks/components/TasksItemTarget';
import { TasksWrapper } from 'features/Tasks/components/TasksWrapper';
import useHotkeys from 'hooks/use-hotkeys';
import hotkeys from 'hotkeys-js';
import React, { useRef } from 'react';
import { getKey, createKeyDownHandler } from 'utils.js';

export const Tasks = ({
  instances,
  rangeSelection,
  rangeDraggable,
  dispatch
}) => {
  const ref = useRef();

  const editorOnUndo = () => dispatch({ type: 'UNDO' });
  const editorOnCreateEmpty = () => {
    const data = {
      type: 'ADD_EMPTY'
    };
    dispatch(data);
  };
  const editorOnCreate = ({ key, offset }) => {
    dispatch({
      type: 'SPLIT',
      payload: {
        key,
        offset
      }
    });
  };
  const editorOnFocusFromLeft = ({ key }) => {
    dispatch({
      type: 'FOCUS_FROM_LEFT',
      payload: {
        key
      }
    });
  };

  const editorFocusUp = ({ key, offset }) => {
    dispatch({
      type: 'FOCUS_UP',
      payload: {
        key,
        offset
      }
    });
  };
  const editorFocusDown = ({ key, offset }) => {
    dispatch({
      type: 'FOCUS_DOWN',
      payload: {
        key,
        offset
      }
    });
  };
  const editorOnRemove = ({ key }) => {
    dispatch({
      type: 'REMOVE',
      payload: {
        key
      }
    });
  };
  const editorOnChange = ({ key, value, operations }) => {
    dispatch({
      type: 'SAVE',
      payload: {
        key,
        value,
        operations
      }
    });
  };
  const editorJoin = ({ key }) => {
    dispatch({
      type: 'JOIN',
      payload: {
        key
      }
    });
  };
  const editorToogleRelation = ({ key, shift }) => {
    dispatch({
      type: 'TOGGLE_RELATION',
      payload: {
        key,
        shift
      }
    });
  };
  const editorOnReplace = ({
    indexSource,
    indexDestination,
    depth
  }) => {
    dispatch({
      type: 'REPLACE',
      payload: {
        indexSource,
        indexDestination,
        depth
      }
    });
  };

  const editorOnSelectionInitDown = ({ key }) => {
    dispatch({
      type: 'SELECTION_INIT_DOWN',
      payload: {
        key
      }
    });
  };

  const editorOnSelectionInitUp = ({ key }) => {
    dispatch({
      type: 'SELECTION_INIT_UP',
      payload: {
        key
      }
    });
  };
  const editorOnFocusFromFocus = ({ key }) => {
    const data = {
      type: 'FOCUS_FROM_FOCUS',
      payload: {
        key
      }
    };
    dispatch(data);
  };

  const setIndexDraggable = ({ key }) => {
    if (!!rangeSelection) return;
    const data = {
      type: 'CHANGE_SELECTION_DRAGGABLE',
      payload: {
        key
      }
    };
    dispatch(data);
  };

  const editorProps = {
    refForWrapper: ref,
    onChange: editorOnChange,
    onCreate: editorOnCreate,
    onFocusFromFocus: editorOnFocusFromFocus,
    onFocusFromLeft: editorOnFocusFromLeft,
    onFocusInstanceDown: editorFocusDown,
    onFocusInstanceUp: editorFocusUp,
    onJoin: editorJoin,
    onRedo: editorOnUndo,
    onRemove: editorOnRemove,
    onToggleRelation: editorToogleRelation,
    onReplace: editorOnReplace,
    onSelectionInitDown: editorOnSelectionInitDown,
    onSelectionInitUp: editorOnSelectionInitUp
  };

  useHotkeys(
    'command+z',
    createKeyDownHandler(editorOnUndo)
  );
  useHotkeys(
    'shift+up',
    createKeyDownHandler(() => {
      dispatch({
        type: 'SELECTION_MOVE_UP'
      });
    })
  );
  useHotkeys(
    'shift+down',
    createKeyDownHandler(e => {
      dispatch({
        type: 'SELECTION_MOVE_DOWN'
      });
    })
  );
  useHotkeys(
    'delete,backspace',
    createKeyDownHandler(e => {
      e.preventDefault();
      e.stopPropagation();
      dispatch({
        type: 'REMOVE_BY_RANGE'
      });
    })
  );
  useHotkeys(
    'shift+tab',
    createKeyDownHandler(e => {
      e.preventDefault();
      dispatch({
        type: 'TOGGLE_RELATION_SELECTION',
        payload: {
          shift: hotkeys.isPressed('shift')
        }
      });
    })
  );
  useHotkeys(
    'tab',
    createKeyDownHandler(e => {
      e.preventDefault();
      dispatch({
        type: 'TOGGLE_RELATION_SELECTION',
        payload: {
          shift: hotkeys.isPressed('shift')
        }
      });
    })
  );
  useHotkeys(
    'escape',
    createKeyDownHandler(e => {
      e.preventDefault();
      dispatch({
        type: 'SELECTION_RESET'
      });
    })
  );

  return instances.size === 0 ? (
    <TasksPlaceholder onClick={editorOnCreateEmpty} />
  ) : (
    <>
      <TasksWrapper>
        <div ref={ref}>
          {instances
            .slice(0, rangeDraggable[0])
            .map((instance, index) => {
              const key = getKey(instance);
              return (
                <TasksItemTarget
                  key={key}
                  setIndexDraggable={setIndexDraggable}
                  instances={instances}
                  taskProps={{
                    id: key,
                    editor: instance,
                    ...editorProps
                  }}
                />
              );
            })}

          <ConditionalWrap
            condition={!!rangeSelection}
            wrap={children => (
              <TasksSelected>{children}</TasksSelected>
            )}
          >
            <TasksItemSource
              indexSource={rangeDraggable[0]}
              instances={instances.slice(
                rangeDraggable[0],
                rangeDraggable[1] + 1
              )}
              editorProps={editorProps}
              setIndexDraggable={setIndexDraggable}
            />
          </ConditionalWrap>
          {instances
            .slice(rangeDraggable[1] + 1, instances.size)
            .map((instance, index) => {
              const key = getKey(instance);
              return (
                <TasksItemTarget
                  key={key}
                  setIndexDraggable={setIndexDraggable}
                  instances={instances}
                  taskProps={{
                    id: key,
                    editor: instance,
                    ...editorProps
                  }}
                />
              );
            })}
        </div>
      </TasksWrapper>
      <TasksPlaceholder
        showIcon={false}
        onClick={editorOnCreateEmpty}
      />
    </>
  );
};
