import {
  findIndexByKey,
  getInstanceDepth
} from 'features/Home/helpers';
import { TasksItemWrapped } from 'features/Tasks/components/TasksItemWrapped';
import { DND_BASIC_TYPE } from 'features/Tasks/constants';
import { compose } from 'lodash/fp';
import React, { useRef, useState } from 'react';
import { DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';
import { BOX_SIZE } from 'shared/constants';

/*
  Need to inject ref to get measurements of a DOM node container
  from hover handler
*/
const injectRef = () => Component => props => {
  const ref = useRef(null);
  return <Component refForBound={ref} {...props} />;
};

const injectVisualFluffforDND = () => Component => props => {
  const [dividerType, setDividerType] = useState('none');
  const [dividerDepth, setDividerDepth] = useState(1);
  return (
    <>
      <Component
        {...props}
        setDividerType={setDividerType}
        setDividerDepth={setDividerDepth}
        dividerType={dividerType}
        dividerDepth={dividerDepth}
      />
    </>
  );
};

const target = {
  drop(props, monitor) {
    const {
      dividerType,
      dividerDepth,
      taskProps,
      instances
    } = props;
    const key = taskProps.id;
    const index = findIndexByKey(key)(instances);
    return {
      indexDestination:
        dividerType === 'TOP' ? index : index + 1,
      depth: dividerDepth
    };
  },

  hover(props, monitor) {
    const {
      refForBound,
      taskProps,
      setDividerDepth,
      setDividerType,
      instances
    } = props;
    const key = taskProps.id;
    const instance = taskProps.editor;
    const index = findIndexByKey(key)(instances);

    const isCursorIsOverTopHalf = () => {
      const node = findDOMNode(refForBound.current);
      const bounds = node.getBoundingClientRect();
      const geoYOfTheMiddle =
        (bounds.bottom - bounds.top) / 2;
      const geoClientOffset = monitor.getClientOffset();
      const geoYClient = geoClientOffset.y - bounds.top;
      return geoYClient <= geoYOfTheMiddle;
    };

    const isTop = isCursorIsOverTopHalf();

    const depthInstanceCurrent = getInstanceDepth(instance);
    const depthInstancePrevious =
      index === 0
        ? null
        : getInstanceDepth(instances.get(index - 1));
    const depthInstanceNext =
      index === instances.size - 1
        ? null
        : isTop
        ? getInstanceDepth(instances.get(index))
        : getInstanceDepth(instances.get(index + 1));

    const depthFrom =
      depthInstanceNext === 0 || depthInstanceNext === null
        ? 0
        : depthInstanceNext - 1;
    const depthTo =
      (index === 0 && isTop) || (index === 1 && isTop)
        ? 0
        : isTop
        ? depthInstancePrevious + 1
        : depthInstanceCurrent + 1;

    const geoElementOffset = monitor.getDifferenceFromInitialOffset();
    const depthMouse = Math.floor(
      (geoElementOffset.x - BOX_SIZE / 2) / BOX_SIZE
    );

    const depth =
      depthMouse <= depthFrom
        ? depthFrom
        : depthMouse >= depthTo
        ? depthTo
        : depthMouse;

    setDividerDepth(depth);
    setDividerType(isTop ? 'TOP' : 'BOTTOM');
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

const TasksItemTargetComponent = ({
  isOver,
  refForBound,
  connectDropTarget,
  dividerType,
  dividerDepth,
  setIndexDraggable,
  taskProps
}) => {
  const key = taskProps.id;
  const onMouseEnter = () => setIndexDraggable({ key });

  return (
    <div ref={refForBound}>
      {connectDropTarget(
        <div>
          <TasksItemWrapped
            dividerDepth={dividerDepth}
            dividerType={dividerType}
            isOver={isOver}
            onMouseEnter={onMouseEnter}
            taskProps={taskProps}
            left={0}
            visible={false}
          />
        </div>
      )}
    </div>
  );
};

export const TasksItemTarget = compose(
  injectRef(),
  injectVisualFluffforDND(),
  DropTarget(DND_BASIC_TYPE, target, collect)
)(TasksItemTargetComponent);
