import { HomeAppBar } from 'features/Home/components/HomeAppBar';
import {
  getDraggableEndAuto,
  getLastMidnight
} from 'features/Home/helpers';
import {
  STORAGE_KEY_FOR_INSTANCES,
  STORAGE_KEY_FOR_SETTINGS
} from 'shared/constants';
import { reducer } from 'features/Home/reducer';
import { selectFiltered } from 'features/Home/selectors';
import { Tasks } from 'features/Tasks';
import { useLocalStorage } from 'hooks/use-localstorage';
import { List } from 'immutable';
import moment from 'moment';
import React, { useEffect, useReducer } from 'react';
import { deserializeInstances } from 'shared/helpers';
import { Redirect } from 'react-router-dom';

const getInitialStateForReducer = ({ db }) => {
  const date = getLastMidnight();
  const instances = selectFiltered(db)({
    mDate: moment(date)
  });
  return {
    date,
    instances: instances,
    history: List(),
    rangeSelection: null,
    rangeDraggable: [
      instances.size >= 0 ? 0 : undefined,
      getDraggableEndAuto({
        instances: instances,
        rangeDraggableStart: 0
      })
    ]
  };
};

export const Home = () => {
  const [db, setDB] = useLocalStorage({
    key: STORAGE_KEY_FOR_INSTANCES,
    initialValue: {},
    deserialize: deserializeInstances
  });

  const [{ SHOW_ABOUT }] = useLocalStorage({
    key: STORAGE_KEY_FOR_SETTINGS,
    initialValue: {}
  });
  const [
    { instances, date, rangeSelection, rangeDraggable },
    dispatch
  ] = useReducer(
    reducer,
    { db },
    getInitialStateForReducer
  );
  const dispatchDateNext = () => {
    const action = {
      type: 'DATE_NEXT'
    };
    dispatch(action);
  };
  const dispatchDatePrev = () => {
    const action = {
      type: 'DATE_PREV'
    };
    dispatch(action);
  };

  useEffect(
    function saveNewInstances() {
      const addInstances = db => ({
        ...db,
        [date.getTime()]: instances
      });
      setDB(addInstances);
    },
    [instances, date, setDB]
  );

  useEffect(
    function dispatchSetInstances() {
      const action = {
        type: 'SET_INSTANCES',
        payload: {
          instances: selectFiltered(db)({
            mDate: moment(date)
          })
        }
      };
      dispatch(action);
    },
    [date, db]
  );

  return (
    <div className={'page-wrapper'}>
      {SHOW_ABOUT !== false ? (
        <Redirect to={'/about'} />
      ) : null}

      <HomeAppBar
        date={date}
        dispatchDateNext={dispatchDateNext}
        dispatchDatePrev={dispatchDatePrev}
      />
      <Tasks
        instances={instances}
        rangeSelection={rangeSelection}
        rangeDraggable={rangeDraggable}
        dispatch={dispatch}
      />
    </div>
  );
};
