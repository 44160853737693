import { Typography } from '@material-ui/core';
import React from 'react';

export const TasksPlaceholder = ({
  showIcon = true,
  ...props
}) => (
  <div
    style={{
      display: 'flex',
      flexGrow: '1',
      cursor: 'text'
    }}
    {...props}
  >
    <div
      style={{
        flex: '1',
        flexGrow: '1',
        height: '100%',
        padding: '120px'
      }}
    >
      {showIcon ? (
        <Typography align={'center'} variant={'h4'}>
          +
        </Typography>
      ) : null}
    </div>
  </div>
);
