import { Task } from 'features/Task';
import { TasksHightlight } from 'features/Tasks/components/TasksHighlight';
import { TasksItemIcon } from 'features/Tasks/components/TasksItemIcon';
import React from 'react';
import { BOX_SIZE } from 'shared/constants';

export const TasksItemWrapped = ({
  isOver,
  onMouseEnter,
  onMouseOver,
  dividerType,
  dividerDepth,
  taskProps,
  left,
  visible
}) => {
  return (
    <div style={{ flex: 1, position: 'relative' }}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseOver={onMouseOver}
      >
        {isOver ? (
          <TasksHightlight
            type={dividerType}
            depth={dividerDepth + 1}
          />
        ) : null}

        <div
          style={{
            display: 'flex',
            position: 'relative'
          }}
        >
          <TasksItemIcon visible={visible} left={left} />
          <div
            style={{
              flex: '1',
              paddingLeft: `${BOX_SIZE}px`
            }}
          >
            <Task {...taskProps} />
          </div>
        </div>
      </div>
    </div>
  );
};
