import {
  CssBaseline,
  MuiThemeProvider
} from '@material-ui/core';

import { Home } from 'features/Home';
import { About as LandingPage1 } from 'features/About';
import { LandingPage2 } from 'features/LandingPage2';
import { Stats } from 'features/Stats';
import React from 'react';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import { theme } from 'theme.js';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    console.log(error);
    console.log(info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default () => (
  <ErrorBoundary>
    <DragDropContextProvider backend={HTML5Backend}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <>
            <Route path={'/'} exact component={Home} />
            <Route path={'/stats'} component={Stats} />
            <Route
              path={'/about'}
              component={LandingPage1}
            />
            <Route path={'/lp1'} component={LandingPage1} />
            <Route path={'/lp2'} component={LandingPage2} />
          </>
        </Router>
      </MuiThemeProvider>
    </DragDropContextProvider>
  </ErrorBoundary>
);
