import { TaskClickCatcher } from 'features/Task/components/TaskClickCatcher';
import { TaskNode } from 'features/Task/components/TaskNode';
import {
  getCurrentOffset,
  getEditorDoneStatus,
  getEditorPureText,
  getEditorSelection,
  getIndexFirstCharOfLine,
  getIndexLastCharOfLine,
  isEditorHasNoText
} from 'features/Task/helpers';
import hotkeys from 'hotkeys-js';
import { hash } from 'immutable';
import React, { memo, useRef } from 'react';
import { Editor } from 'slate-react';

const renderBlock = (props, editor, next) => {
  const { node } = props;

  switch (node.type) {
    case 'paragraph':
      return <TaskNode {...props} />;
    case 'placeholder':
      return <TaskNode {...props} />;
    default:
      next();
  }
};

const memoCheck = (prev, next) => {
  const isHashsAreEqual =
    hash(prev.editor) === hash(next.editor);
  return isHashsAreEqual;
};

export const Task = memo(
  ({
    id: key,
    editor,
    refForWrapper,
    onChange,
    onCreate,
    onFocusFromFocus,
    onFocusFromLeft,
    onFocusInstanceDown,
    onFocusInstanceUp,
    onSelectionInitUp,
    onSelectionInitDown,
    onJoin,
    onRedo,
    onRemove,
    onToggleRelation
  }) => {
    const ref = useRef(editor);
    const onKeyDown = (event, editor, next) => {
      if (
        // key backspace
        event.keyCode === 8 &&
        isEditorHasNoText(editor)
      ) {
        event.preventDefault();
        event.stopPropagation();
        onRemove({ key });
        return false;
      }

      // key backspace
      if (
        event.keyCode === 8 &&
        getCurrentOffset(editor) === 0 &&
        !getEditorSelection(editor)
      ) {
        event.preventDefault();
        event.stopPropagation();

        onJoin({ key });
        return false;
      }

      // space + shift
      if (event.keyCode === 32) {
        if (hotkeys.isPressed('shift')) {
          event.preventDefault();
          event.stopPropagation();
          editor.setNodeByPath(['0'], {
            data: {
              isDone: !getEditorDoneStatus(editor)
            }
          });
          return true;
        }
      }

      // key up + shift
      if (
        event.keyCode === 38 &&
        hotkeys.isPressed('shift')
      ) {
        if (
          getEditorSelection(editor).length > 0 ||
          getCurrentOffset(editor) === 0
        ) {
          event.preventDefault();
          event.stopPropagation();
          onSelectionInitUp({ key });
          return true;
        } else {
          return true;
        }
      }

      // key up
      if (event.keyCode === 38) {
        const offsetOfLine =
          getCurrentOffset(editor) -
          getIndexFirstCharOfLine();
        event.preventDefault();
        event.stopPropagation();

        onFocusInstanceUp({ offset: offsetOfLine, key });
        return false;
      }

      // key down + shift
      if (
        event.keyCode === 40 &&
        hotkeys.isPressed('shift')
      ) {
        editor.deselect();

        // event.preventDefault();
        // event.stopPropagation();
        onSelectionInitDown({ key });
        return false;
      }

      // key down
      if (event.keyCode === 40) {
        if (
          getIndexLastCharOfLine() ===
            getEditorPureText(editor).length ||
          getEditorPureText(editor).length === 0
        ) {
          event.preventDefault();
          event.stopPropagation();
          const offsetOfLine =
            getCurrentOffset(editor) -
            getIndexFirstCharOfLine();
          onFocusInstanceDown({
            offset: offsetOfLine,
            key
          });
          return false;
        }
      }

      // command + z
      if (
        event.keyCode === 90 &&
        hotkeys.isPressed('command')
      ) {
        event.preventDefault();
        event.stopPropagation();
        onRedo();
        return false;
      }

      // [shift] + tab
      if (event.keyCode === 9) {
        event.preventDefault();
        event.stopPropagation();
        onToggleRelation({
          key,
          shift: !!event.shiftKey
        });
        return false;
      }

      // enter
      if (event.keyCode === 13) {
        event.preventDefault();
        const offset = getCurrentOffset(editor);

        onCreate({
          key,
          offset
        });
        return false;
      }

      return next();
    };
    const onChangeWithKey = props => {
      console.log('on change with key');
      onChange({
        ...props,
        key
      });
    };
    /*
    NODE
    - There is a need to cancel ondrop event
    - Otherwise focus event is triggered
    */
    const onDrop = e => e.preventDefault();
    const onClickCatcher = () => onFocusFromLeft({ key });

    return (
      <TaskClickCatcher
        refForWrapper={refForWrapper}
        onClick={onClickCatcher}
      >
        <Editor
          autoCorrect={false}
          autoFocus={false}
          spellCheck={false}
          id={key}
          ref={ref}
          onChange={onChangeWithKey}
          onDrop={onDrop}
          onKeyDown={onKeyDown}
          renderBlock={renderBlock}
          value={editor}
        />
      </TaskClickCatcher>
    );
  },
  memoCheck
);
