import React from 'react';

import {
  BOX_SIZE,
  BOX_SIZE_PADDING
} from 'shared/constants';
import DragIndicator from '@material-ui/icons/DragIndicator';

export const TasksItemIcon = ({
  visible = true,
  left,
  onMouseOver,
  onMouseEnter
}) => (
  <DragIndicator
    style={{
      position: 'absolute',
      left,
      opacity: visible ? 1 : 0,
      cursor: 'grab',
      margin: `${BOX_SIZE_PADDING}px`,
      height: `${BOX_SIZE - 2 * BOX_SIZE_PADDING}px`,
      width: `${BOX_SIZE - 2 * BOX_SIZE_PADDING}px`
    }}
    color={'action'}
    onMouseEnter={onMouseEnter}
    onMouseOver={onMouseOver}
  />
);
