import { DND_BASIC_TYPE } from 'features/Tasks/constants';
import { compose } from 'lodash/fp';
import React from 'react';
import { DragSource } from 'react-dnd';
import { getKey } from 'utils.js';
import { getInstanceDepth } from 'features/Home/helpers';
import { BOX_SIZE } from 'shared/constants';
import { TasksItemWrapped } from 'features/Tasks/components/TasksItemWrapped';

const source = {
  beginDrag(props) {
    // Return the data describing the dragged item
    const item = {
      instances: props.instances,
      instanceKeyFrom: getKey(props.instances.get(0))
    };
    return item;
  },

  endDrag(props, monitor, component) {
    console.log(props);
    if (!monitor.didDrop()) {
      return;
    }
    const { indexSource } = props;
    const {
      indexDestination,
      depth
    } = monitor.getDropResult();
    const { onReplace } = props.editorProps;

    const onReplaceData = {
      indexSource,
      indexDestination,
      depth
    };

    onReplace(onReplaceData);
  }
};

/*
 * Specifies which props to inject into your component.
 */
function collect(connect, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connect.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

const TasksItemSourceComponent = ({
  isDragging,
  isOver,
  indexSource,
  connectDragSource,
  instances,
  editorProps,
  setIndexDraggable
}) => {
  return (
    <div>
      {connectDragSource(
        <div>
          {instances.map((instance, index) => {
            const key = getKey(instance);
            const depth = getInstanceDepth(instance);
            const onMouseOver = e =>
              setIndexDraggable({ key });

            return (
              <TasksItemWrapped
                key={key}
                onMouseOver={onMouseOver}
                left={depth * BOX_SIZE}
                visible={index === 0}
                taskProps={{
                  id: key,
                  editor: instance,
                  ...editorProps
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export const TasksItemSource = compose(
  DragSource(DND_BASIC_TYPE, source, collect)
)(TasksItemSourceComponent);
