import { Value } from 'slate';

export const generateKey = () => {
  return String(Math.random());
};

export const getKey = instance => {
  return instance.getIn(['document', 'data', 'key']);
};

export const createKeyDownHandler = cb => (e, ...args) => {
  if (e.type === 'keydown') {
    cb(e, ...args);
  }
};

export const createInstance = ({
  key,
  text,
  data,
  offset,
  isFocused,
  depth = 0,
  ...props
}) => {
  const json = {
    object: 'value',
    document: {
      object: 'document',
      data: {
        key,
        depth
      },
      nodes: [
        {
          object: 'block',
          type: 'paragraph',
          data,
          nodes: [
            {
              object: 'text',
              text
            }
          ]
        }
      ]
    }
  };

  if (isFocused) {
    json.selection = {
      anchor: {
        path: [0, 0],
        offset:
          !!offset || offset === 0 ? offset : text.length
      },
      focus: {
        path: [0, 0],
        offset:
          !!offset || offset === 0 ? offset : text.length
      },
      isFocused,
      marks: null
    };
  }
  return Value.fromJSON(json);
};
