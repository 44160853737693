import { createInstance, getKey } from 'utils';
import { isNumber } from 'lodash/fp';

export const isOperationIsRevertable = operations => {
  return (
    isOperationIsTextMod(operations) ||
    isOperationIsSetNode(operations)
  );
};

export const isOperationIsTextMod = operations => {
  const MODES = ['insert_text', 'replace_text'];
  return !!operations.find(operation => {
    const type = operation.get('type');
    return MODES.includes(type);
  });
};

export const isOperationIsSetNode = operations => {
  const MODES = ['set_node'];
  return !!operations.find(operation => {
    const type = operation.get('type');
    return MODES.includes(type);
  });
};

export const getInstanceDataBlock = instance => {
  try {
    return instance
      .getIn(['document', 'nodes', '0', 'data'])
      .toJS();
  } catch (e) {
    return undefined;
  }
};

export const getInstanceTime = instance => {
  try {
    return instance.getIn(['document', 'data', 'time']);
  } catch (e) {
    return undefined;
  }
};

export const getInstanceDepth = instance => {
  if (!instance) throw new Error('Instance is required');
  try {
    return instance.getIn(['document', 'data', 'depth']);
  } catch (e) {
    return undefined;
  }
};

export const getInstanceText = instance => {
  try {
    return instance.getIn([
      'document',
      'nodes',
      '0',
      'nodes',
      '0',
      'text'
    ]);
  } catch (e) {
    return '';
  }
};

export const getInstancesFocus = instance => {
  return instance.getIn(['selection', 'isFocused']);
};

export const getInstancesCompletedCount = instances => {
  return instances.filter(instance => {
    const data = getInstanceDataBlock(instance);
    return !!data && !!data.isDone;
  }).size;
};

export const getInstancesDepthMatrix = instances => {
  return instances.map(getInstanceDepth).toJS();
};

export const copyInstanceAndDoGood = ({
  instance,
  ...props
}) => {
  const instanceNew = createInstance({
    key: getKey(instance),
    text: getInstanceText(instance),
    time: getInstanceTime(instance),
    data: getInstanceDataBlock(instance),
    depth: getInstanceDepth(instance),
    ...props
  });

  return instanceNew;
};

export const findIndexByKey = key => instances => {
  if (!key) throw new Error('key should be defined');
  return instances.findIndex(
    instance => getKey(instance) === key
  );
};

export const findLastGroupIndex = indexFirst => instances => {
  const depth = getInstanceDepth(instances.get(indexFirst));

  const indexLastDown = instances.findIndex(instance => {
    return (
      instances.indexOf(instance) > indexFirst &&
      getInstanceDepth(instance) <= depth
    );
  });
  return indexLastDown >= 0
    ? indexLastDown - 1
    : instances.size - 1;
};

export const isIndexInIndexRange = index => range => {
  const opt1 = range[0] <= index && index <= range[1];

  const opt2 = range[0] >= index && index >= range[1];
  return opt1 || opt2;
};

export const mapRangeWithOffset = ([r1, r2]) => offset => {
  return [
    r1 - offset < 0 ? 0 : r1 - offset,
    r2 - offset + 1 < 0 ? 0 : r2 - offset + 1
  ];
};

export const getDraggableEndAuto = ({
  instances,
  rangeDraggableStart
}) => {
  if (instances.size === 0) return undefined;
  return isNumber(rangeDraggableStart) && instances.size > 0
    ? findLastGroupIndex(rangeDraggableStart)(instances)
    : undefined;
};

export const isRangeIsAscending = range =>
  !!range && range[0] <= range[1];
export const isRangeIsSingle = range =>
  range[0] === range[1];
export const isRangeIsGroup = ({
  rangeSelection,
  instances
}) => {
  if (isRangeIsSingle(rangeSelection)) return true;

  const depths = instances
    .slice(rangeSelection[0], rangeSelection[1] + 1)
    .map(instance => getInstanceDepth(instance))
    .toJS();
  const depthsSorted = depths.concat().sort();

  return (
    depths[0] === depthsSorted[0] &&
    depthsSorted[0] < depthsSorted[1]
  );
};

export const getTravelIndexPrevious = index => {
  return Math.max(index - 1, 0);
};

export const getLastMidnight = () => {
  const d = new Date();
  d.setMilliseconds(0);
  d.setSeconds(0);
  d.setMinutes(0);
  d.setHours(0);
  return d;
};
