import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { getDayHeader } from 'shared/helpers';

export const HomeAppBar = ({
  date,
  dispatchDateNext,
  dispatchDatePrev
}) => (
  <AppBar elevation={0} position={'static'}>
    <Grid container>
      <Grid item xs={2}>
        <Toolbar variant={'dense'}>
          <Typography
            style={{ flex: '1', color: 'white' }}
            variant={'button'}
          >
            AlwaysNow
          </Typography>
        </Toolbar>
      </Grid>
      <Grid item xs={8}>
        <Toolbar
          variant={'dense'}
          style={{ justifyContent: 'center' }}
        >
          <Button onClick={dispatchDatePrev}>{'<'}</Button>
          <Typography
            variant={'subtitle2'}
            align={'center'}
            style={{ minWidth: '120px', color: 'white' }}
          >
            {getDayHeader(moment(date))}
          </Typography>
          <Button onClick={dispatchDateNext}>{'>'}</Button>
        </Toolbar>
      </Grid>
      <Grid item xs={2}>
        <Toolbar
          variant={'dense'}
          style={{
            visibility: 'hidden',
            minHeight: undefined,
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          <Button component={Link} to={'/stats'}>
            Stats
          </Button>
        </Toolbar>
      </Grid>
    </Grid>
  </AppBar>
);
