import { grey } from '@material-ui/core/colors';
import React from 'react';
import { BOX_SIZE } from 'shared/constants';

export const TasksHightlight = ({
  type,
  depth = 0,
  height = 4
}) => {
  return (
    <div
      style={{
        top:
          type === 'TOP' ? `${-height / 2}px` : undefined,
        bottom:
          type === 'BOTTOM'
            ? `${-height / 2}px`
            : undefined,
        position: 'absolute',
        height: `${height}px`,
        width: '100%',
        zIndex: 99999
      }}
    >
      <div style={{ display: 'flex' }}>
        {[...new Array(depth)].map(() => (
          <div
            key={Math.random()}
            style={{
              width: `${BOX_SIZE - 2}px`,
              padding: '0px 1px',
              height: `${height}px`
            }}
          >
            <div
              style={{
                backgroundColor: grey[400]
              }}
            />
          </div>
        ))}
        <div
          style={{
            flex: '1',
            backgroundColor: grey[400],
            height: `${height}px`
          }}
        />
      </div>
    </div>
  );
};
