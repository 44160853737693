import { List } from 'immutable';
import { Value } from 'slate';

export const getDayHeader = m => {
  return m.calendar(null, {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd',
    sameElse: 'DD/MM/YYYY'
  });
};

export const deserializeInstances = jsonFromLocalStorage => {
  var res = {};
  for (let [time, instancesJSON] of Object.entries(
    jsonFromLocalStorage
  )) {
    res[time] = List(
      instancesJSON.map(instance =>
        Value.fromJSON(instance)
      )
    );
  }
  return res;
};
